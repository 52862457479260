@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Bengali:wght@300;400;500;700&family=Roboto:wght@400;500;700&display=swap");

* {
  font-family: "Noto Serif Bengali", "Roboto", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #fac02c95;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
